import { CallStatuses } from 'lux/models/employer/vacancyresponses/calls.types';
import { CallItem, TopicCall } from 'lux/models/topicsCallHistory';

export const findCall = (calls: TopicCall): CallItem => {
    return calls.items.find((call) => call.status === CallStatuses.CallEnded) || calls.items[calls.items.length - 1];
};

const getCallDuration = (calls: TopicCall, trls: { min: string; sec: string }): string | null => {
    if (!calls?.pickedUpPhone) {
        return null;
    }

    const duration = findCall(calls)?.durationSeconds;

    if (!duration) {
        return null;
    }

    if (duration / 60 >= 1) {
        return `${Math.floor(duration / 60)} ${trls.min}`;
    }
    return `${duration} ${trls.sec}`;
};

export default getCallDuration;
