function getCoordinatesForPercent(percent: number) {
    const radianValue = 2 * Math.PI * (percent / 100);
    const x = Math.cos(radianValue);
    const y = Math.sin(radianValue);
    return [x, y].map((value) => Math.round(value * 100) / 100);
}
export default {
    getPath: (percent: number): string => {
        const largeArcFlag = percent > 50 ? 1 : 0;
        const [coordX, coordY] = getCoordinatesForPercent(percent);
        return `M 1 0 A 1 1 0 ${largeArcFlag} 1 ${coordX} ${coordY || -0.001} L 0 0`;
    },
};
