export enum CallStatuses {
    DidNotConnect = 'DID_NOT_CONNECT',
    CallEnded = 'CALL_ENDED',
}

export interface Call {
    id: number;
    creationTime: string;
    durationSeconds?: number;
    callerNumber: string;
    lastChangeTime: string;
    status: CallStatuses;
    formattedCallerNumber: string;
}
