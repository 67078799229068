import { DefaultRootState } from 'react-redux';
import { push } from 'connected-react-router';
import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { Action } from '@hh.ru/redux-create-reducer';

import { updateUrl } from 'Modules/url';
import { AddNotification } from 'lux/components/Notifications/Provider/types';
import fetcher from 'lux/modules/fetcher';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';

interface Payload {
    method: string;
    href: keyof FetcherPostApi;
    substate: string;
    topicId: string;
}

const doActionWithApplicantNegotiation: ActionCreator<
    ThunkAction<Promise<unknown>, DefaultRootState, unknown, Action>
> =
    ({ method, href, substate, topicId }: Payload, addNotification: AddNotification) =>
    (dispatch, getState) => {
        if (method === 'GET') {
            dispatch(push(updateUrl(href, { topicId })));
            return Promise.resolve();
        }

        return fetcher
            .postFormData(href, {
                topic: topicId,
                query: getState().router.location.search,
                substate,
            })
            .then(
                () => dispatch(push(window.location)),
                (err) => defaultRequestErrorHandler(err, addNotification)
            );
    };

export default doActionWithApplicantNegotiation;
