import { FC } from 'react';
import classNames from 'classnames';

import AdsExternalBanner from 'lux/components/Banner/AdsExternalBanner';
import { BannerPlace } from 'lux/models/banners';
import { useSelector } from 'lux/modules/useSelector';

import styles from './responses.less';

const ListBanner: FC = () => {
    const bannerL = useSelector((state) => state.banners[BannerPlace.AFTER_4TH_TOPIC_L]);
    const bannerM = useSelector((state) => state.banners[BannerPlace.AFTER_4TH_TOPIC_M]);
    const bannerS = useSelector((state) => state.banners[BannerPlace.AFTER_4TH_TOPIC_S]);
    const bannerXS = useSelector((state) => state.banners[BannerPlace.AFTER_4TH_TOPIC_XS]);

    if (!bannerL && !bannerM && !bannerS && !bannerXS) {
        return null;
    }

    return (
        <tr className={classNames(styles.responsesTableRow, styles.responsesTableRowBanner)}>
            <td className={styles.responsesTableRowCell} colSpan={6}>
                <div className="banner-substrate">
                    {bannerL && bannerL.length > 0 && (
                        <div className="banner-place-wrapper_show-on-l">
                            <AdsExternalBanner {...bannerL[0]} />
                        </div>
                    )}
                    {bannerM && bannerM.length > 0 && (
                        <div className="banner-place-wrapper_show-on-m">
                            <AdsExternalBanner {...bannerM[0]} />
                        </div>
                    )}
                    {bannerS && bannerS.length > 0 && (
                        <div className="banner-place-wrapper_show-on-s">
                            <AdsExternalBanner {...bannerS[0]} />
                        </div>
                    )}
                    {bannerXS && bannerXS.length > 0 && (
                        <div className="banner-place-wrapper_show-on-xs">
                            <AdsExternalBanner {...bannerXS[0]} />
                        </div>
                    )}
                </div>
            </td>
        </tr>
    );
};

export default ListBanner;
