import { useCallback } from 'react';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import Gap from 'bloko/blocks/gap';
import Link from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';

import translation from 'lux/components/translation';
import useToggleState from 'lux/hooks/useToggleState';
import { useSelector } from 'lux/modules/useSelector';

import AutosearchMessengersModal from 'lux/components/AutosearchMessengers/AutosearchMessengersModal';

const analyticsData = { category: 'applicant', action: 'chatbot-messenger-setup', label: 'negotiations' };

const AutoSearchMessengersTip = ({ trls }) => {
    const chatBot = useSelector((store) => store.chatBot);
    const [modalIsVisible, toggleModal] = useToggleState(false);
    const handleButtonClick = useCallback(() => {
        toggleModal();
        Analytics.sendEvent(analyticsData.category, analyticsData.action, analyticsData.label);
    }, [toggleModal]);

    if (!chatBot) {
        return null;
    }

    return (
        <div className="autosearch-messengers-tip-wrap">
            <div className="autosearch-messengers-tip">
                <span className="autosearch-messengers-tip-text-wrap">
                    <Gap right Element="span">
                        <Text Element="span" strong>
                            {trls[AutoSearchMessengersTip.trls.tip]}
                        </Text>{' '}
                        {trls[AutoSearchMessengersTip.trls.text]}
                    </Gap>
                </span>
                <Link Element={'button'} onClick={handleButtonClick}>
                    {trls[AutoSearchMessengersTip.trls.button]}
                </Link>
            </div>
            <AutosearchMessengersModal isVisible={modalIsVisible} handleCloseModal={toggleModal} />
        </div>
    );
};
AutoSearchMessengersTip.trls = {
    tip: 'autosearch.tip',
    text: 'autosearch.tip.text',
    button: 'autosearch.tip.button',
};

AutoSearchMessengersTip.propTypes = {
    trls: PropTypes.object.isRequired,
};

export default translation(AutoSearchMessengersTip);
