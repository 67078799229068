import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column';

import JobSearchStatusBanner from 'lux/components/Applicant/JobSearchStatus/JobSearchStatusBanner';
import AutoSearchMessengersTip from 'lux/components/AutosearchMessengers/AutoSearchMessengersTip';
import ClickmeList from 'lux/components/Clickme/List';
import ClickmeReferralLink from 'lux/components/Clickme/ReferralLink';
import ContainerForMicroFrontend from 'lux/components/CodeInjector/ContainerForMicroFrontend';
import PageLayout, { HeaderLayout } from 'lux/components/PageLayout';
import MagritteDefaultLayout from 'lux/components/PageLayout/MagritteLayouts/DefaultLayout';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import NegotiationsFilterByActive from 'lux/pages/ApplicantNegotiations/components/ApplicantNegotiations/FiltersByActive';
import NegotiationsList from 'lux/pages/ApplicantNegotiations/components/ApplicantNegotiations/List';
import NegotiationsFilter from 'lux/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/NegotiationsFilter';
import ApplicantSuitableVacancies from 'lux/pages/ApplicantNegotiations/components/ApplicantSuitableVacancies';
import Surveys from 'lux/pages/ApplicantNegotiations/components/Surveys';

const TrlKeys = {
    title: 'applicant.negotiations.page.title',
};

const ApplicantNegotiations = ({ trls }) => {
    const isNegotiationsMagritteExp = useSelector((state) => state.isNegotiationsMagritteExp);

    if (isNegotiationsMagritteExp) {
        return (
            <PageLayout title={trls[TrlKeys.title]} layout={MagritteDefaultLayout}>
                {/* Магриттизация откликов тут */}
                <NegotiationsFilter />
                <ClickmeList
                    clickmeReferralLink={
                        <Column xs="4" s="8" m="12" l="16">
                            <ClickmeReferralLink />
                        </Column>
                    }
                />
                <ContainerForMicroFrontend place="topicInterviewReviewWizard" />
            </PageLayout>
        );
    }

    return (
        <PageLayout title={trls[ApplicantNegotiations.trls.title]} layout={HeaderLayout}>
            <Column xs="4" s="8" m="12" l="16">
                <Surveys />
                <JobSearchStatusBanner />
                <NegotiationsFilterByActive />
                <NegotiationsList />
            </Column>
            <AutoSearchMessengersTip />
            <ApplicantSuitableVacancies />
            <ClickmeList
                clickmeReferralLink={
                    <Column xs="4" s="8" m="12" l="16">
                        <ClickmeReferralLink />
                    </Column>
                }
            />
            <ContainerForMicroFrontend place="topicInterviewReviewWizard" />
        </PageLayout>
    );
};

ApplicantNegotiations.trls = {
    title: 'vacancyresponses.and.invitations.title',
};

ApplicantNegotiations.propTypes = {
    trls: PropTypes.object,
};

export default translation(ApplicantNegotiations);
