import { useElementShown } from '@hh.ru/analytics-js';
import negotiationItemPendingAutoActionSurveyTagButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/negotiation_list/negotiation_item_pending_auto_action_survey_tag_button_click';
import negotiationItemPendingAutoActionSurveyTagElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/negotiation_list/negotiation_item_pending_auto_action_survey_tag_element_shown';
import HSpacing from 'bloko/blocks/hSpacing';
import { BotScaleSmall, IconColor } from 'bloko/blocks/icon';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

import styles from './responses.less';

const TrlKeys = {
    tagText: 'applicant.negotiations.pendingAutoActionSurveyTag.text',
};

interface PendingAutoActionSurveyTagProps {
    topicId: number;
}

const PendingAutoActionSurveyTag: TranslatedComponent<PendingAutoActionSurveyTagProps> = ({ topicId, trls }) => {
    const tagRef = useElementShown(negotiationItemPendingAutoActionSurveyTagElementShown, { topicId });

    return (
        <>
            <VSpacing base={2} />
            <div
                ref={tagRef}
                className={styles.responsesTableRowTopicHasPendingAutoActionSurveyTag}
                onClick={() => negotiationItemPendingAutoActionSurveyTagButtonClick({ topicId })}
            >
                <BotScaleSmall initial={IconColor.Violet30} />
                <HSpacing base={1} />
                <Text>{trls[TrlKeys.tagText]}</Text>
            </div>
        </>
    );
};

export default translation(PendingAutoActionSurveyTag);
