import classnames from 'classnames';

import topicSelectButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/negotiation_list/topic_select_button_click';
import Checkbox from 'bloko/blocks/checkbox';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { Topic } from 'lux/models/applicantNegotiationTopic.types';
import { ManagerActivityData } from 'lux/models/applicantNegotiations/applicantEmployerManagersActivity';
import { ApplicantSubState } from 'lux/models/negotiationTopic.types';
import { useSelector } from 'lux/modules/useSelector';

import ApplicantNegotiationsListEmployerStats from 'lux/pages/ApplicantNegotiations/components/ApplicantNegotiations/EmployerStats';
import ApplicantNegotiationsListTopicStatus from 'lux/pages/ApplicantNegotiations/components/ApplicantNegotiations/ListTopicStatus';

import styles from './responses.less';

enum TopicStatus {
    NotViewed = 'not-viewed',
    Viewed = 'viewed',
    Invitation = 'invitation',
    Discard = 'discard',
}

const INVITATION_STATE = 'INVITATION';
const DISCARD_STATE = 'DISCARD';
const DISCARD_AFTER_INTERVIEW_STATE = 'DISCARD_AFTER_INTERVIEW';
const RESPONSE_STATE = 'RESPONSE';

interface ListStatsColumnProps {
    /** Объект с полями топика */
    topic: Topic;
    /** Объект активности менеджера */
    managerInactiveData: ManagerActivityData;
    /** Флаг выбран ли фильтр активных откликов */
    hasActiveFilter: boolean;
    /** Хендлер изменения топика */
    topicChange: (params: { checked: boolean; topicId: number }) => void;
    /** Флаг выбора топика */
    selected: boolean;
}

const TrlKeys = {
    status: {
        [TopicStatus.Discard]: 'applicant.negotiations.denial',
        [TopicStatus.Invitation]: 'applicant.negotiations.invitation',
        [TopicStatus.NotViewed]: 'applicant.negotiations.notSeenMasc',
        [TopicStatus.Viewed]: 'applicant.negotiations.seenMasc',
    },
    violatesRules: 'applicant.negotiations.employerViolatesRules',
};

const getTopicStatus = (topic: Topic): TopicStatus => {
    const mapping = {
        [TopicStatus.NotViewed]: topic.lastState === RESPONSE_STATE && !topic.viewedByOpponent,
        [TopicStatus.Viewed]: topic.lastState === RESPONSE_STATE && topic.viewedByOpponent,
        [TopicStatus.Invitation]: topic.lastState === INVITATION_STATE,
        [TopicStatus.Discard]: topic.lastState === DISCARD_STATE || topic.lastState === DISCARD_AFTER_INTERVIEW_STATE,
    } as const;

    return Object.keys(mapping).find((key: TopicStatus) => mapping[key]) || TopicStatus.Viewed;
};

const ListStatsColumn: TranslatedComponent<ListStatsColumnProps> = ({
    topic,
    topicChange,
    managerInactiveData,
    hasActiveFilter,
    selected,
    trls,
}) => {
    const employerPoliteness = useSelector(
        (state) => state.applicantEmployerPoliteness.employerPolitenessIndexes[topic.employerId]
    );

    const renderTopicStatus = () => {
        const topicStatus = getTopicStatus(topic);

        return (
            <span
                className={classnames(styles.responsesTableStatus, {
                    [styles.responsesTableStatusInvitation]: topicStatus === TopicStatus.Invitation,
                    [styles.responsesTableStatusDiscard]: topicStatus === TopicStatus.Discard,
                    [styles.responsesTableStatusViolatesRules]: topic.employerViolatesRules,
                })}
                data-qa={classnames({
                    'negotiations-item-invite': topicStatus === TopicStatus.Invitation,
                    'negotiations-item-discard': topicStatus === TopicStatus.Discard,
                    'negotiations-item-not-viewed': topicStatus === TopicStatus.NotViewed,
                    'negotiations-item-viewed': topicStatus === TopicStatus.Viewed,
                })}
            >
                {trls[topic.employerViolatesRules ? TrlKeys.violatesRules : TrlKeys.status[topicStatus]]}
            </span>
        );
    };

    const showEmployerStats = !topic.archived && topic.lastSubState !== ApplicantSubState.Hide;

    return (
        <>
            {hasActiveFilter ? (
                <Checkbox
                    onChange={(event) => {
                        if (event.target.checked) {
                            topicSelectButtonClick({ topicId: topic.id });
                        }
                        topicChange({
                            checked: event.target.checked,
                            topicId: topic.id,
                        });
                    }}
                    wrapperProps={{
                        onClick: (event) => {
                            event.stopPropagation();
                        },
                    }}
                    checked={selected}
                    data-qa="negotiations-item-checkbox"
                >
                    {renderTopicStatus()}
                </Checkbox>
            ) : (
                renderTopicStatus()
            )}
            <span
                className={classnames(
                    styles.responsesTableRowCellVacancyStatus,
                    styles.responsesTableVacancyStatusShowOnXs
                )}
            >
                <ApplicantNegotiationsListTopicStatus archived={topic.archived} lastSubState={topic.lastSubState} />
            </span>
            {!topic.employerViolatesRules && showEmployerStats && (
                <ApplicantNegotiationsListEmployerStats
                    employerPoliteness={employerPoliteness}
                    managerInactiveData={managerInactiveData}
                />
            )}
        </>
    );
};

export default translation(ListStatsColumn);
