import { MouseEventHandler } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import styles from './responses.less';

interface ViewSummaryActionProps {
    topicId: number;
}

const TrlKeys = {
    viewResponsesSummary: 'applicant.negotiations.action.summary',
};

const ViewSummaryAction: TranslatedComponent<ViewSummaryActionProps> = ({ trls, topicId }) => {
    const hasVacancyResponsesSummaryService = useSelector((state) => state.hasVacancyResponsesSummaryService);
    const dispatch = useDispatch();

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.stopPropagation();
        dispatch(
            push(
                hasVacancyResponsesSummaryService
                    ? `/applicant-services/vacancy-summary/${topicId}`
                    : `/article/vacancy_summary?topicId=${topicId}`
            )
        );
    };

    return (
        <button
            type="button"
            className={styles.responsesTableAction}
            onClick={handleClick}
            data-qa={`negotiations-vacancy_summary`}
        >
            {trls[TrlKeys.viewResponsesSummary]}
        </button>
    );
};

export default translation(ViewSummaryAction);
